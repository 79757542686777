export default {
  data() {
    return {};
  },
  methods: {
    routerJump(path) {
      var _path = path ? "/" + path : "/";
      var _code = localStorage.getItem("code");
      this.$router
        .push({ path: _path, query: { code: _code } })
        .catch(() => {});
      window.scrollTo(0, 0);
    },
  },
};
