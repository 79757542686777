import Cookies from "js-cookie";

/**
 * 设置Cookie
 * @param {string} token
 * @param {object} option
 */
export function setCookie(key, token, option) {
  return Cookies.set(key, token, option);
}

/**
 * 获取Cookie
 */
export function getCookie(key) {
  return Cookies.get(key);
}

/**
 * 删除Cookie
 */
export function removeCookie(key) {
  return Cookies.remove(key);
}

/**
 * 存储本地数据
 * @param {string} key
 * @param {object} data
 */
export function setLocalItem(key, data) {
  return localStorage.setItem(key, JSON.stringify(data));
}

/**
 * 获取本地数据
 * @param {string} key
 */
export function getLocalItem(key) {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : undefined;
}

/**
 * 删除本地指定数据
 * @param {string} key
 */
export function removeLocalItem(key) {
  return localStorage.removeItem(key);
}

/**
 * 删除本地所有数据
 */
export function clearLocalItem() {
  return localStorage.clear();
}

// 判断当前设备是否为移动端设备
export function isMobileDevice() {
  // 获取userAgent字符串
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // 使用正则表达式来检查userAgent字符串中是否包含某些关键字
  // 这些关键字通常与移动设备相关
  var mobile =
    /windows phone|iphone|ipad|ipod|android|blackberry|mini|windows ce|palm/i.test(
      userAgent.toLowerCase()
    );

  return mobile ? true : false;
}

/**
 * 针对不同的设备，跳转到对应的链接   pc: www , h5: m
 */
export function jumpToDeviceUrl() {
  console.log(isMobileDevice());
  //     // 完整的URL
  // var fullUrl = window.location.href;

  // // 协议部分，例如 'http:' 或 'https:'
  var protocol = window.location.protocol;

  // // 主机名，包括域名
  var hostname = window.location.hostname;

  // // 端口号（如果有）
  var port = window.location.port;

  // // 路径部分，即文件和目录的路径
  var pathname = window.location.pathname;

  // // 查询字符串，以 '?' 开头
  var search = window.location.search;

  // // 锚点，以 '#' 开头
  var hash = window.location.hash;

  // 本地开发忽略
  if (hostname.indexOf("localhost") !== -1) return;

  var newPort = "";
  var newUrl = "";
  var newHostName = "";

  if (isMobileDevice()) {
    // 当前为移动端设备
    if (hostname.indexOf("m.") === -1) {
      // 不是访问h5链接，应该跳转到h5链接
      newPort = port ? ":" + port : "";
      console.log("应该到h5链接");
      if (hostname.indexOf("www.") !== -1) {
        newHostName = hostname.replace("www.", "m.");
      } else {
        newHostName = "m." + hostname;
      }
      newUrl =
        protocol + "//" + newHostName + newPort + pathname + search + hash;
      console.log("h5 - newUrl:", newUrl);
      window.location.href = newUrl;
    }
  } else {
    // 当前为PC端设备
    if (hostname.indexOf("m.") !== -1) {
      // 不是访问pc链接，应该跳转到pc链接
      console.log("应该到pc链接");

      newPort = port ? ":" + port : "";

      console.log("应该到pc链接");
      newHostName = hostname.replace("m.", "www.");
      newUrl =
        protocol + "//" + newHostName + newPort + pathname + search + hash;
      console.log("pc - newUrl:", newUrl);
      window.location.href = newUrl;
    }
  }
}

/**
 * 针对不同的设备，跳转到对应的链接   pc: www , h5: m
 */

export function saveCode() {
  // // 查询字符串，以 '?' 开头
  let _url = window.location.href;
  let _search = _url.split("?")[1];
  let str;
  let obj = {};

  if (_search) {
    str = _search.split("&");
  }

  if (str) {
    for (let i = 0; i < str.length; i++) {
      let a = str[i].split("=");
      obj[a[0]] = a[1];
    }
  }

  if (obj.code) {
    localStorage.setItem("code", obj.code);
  }
}
