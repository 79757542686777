import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

// 读取配置
function getMessages() {
  const context = require.context("./lang", false, /[a-z0-9-_]+\.json$/i);
  const results = context
    .keys()
    .map((key) => ({ key, locale: key.match(/[a-z0-9-_]+/i)[0] }))
    .reduce(
      (acc, { key, locale }) => ({
        ...acc,
        [locale]: Object.assign({}, context(key)),
      }),
      {}
    );
  return results;
}

export default new VueI18n({
  locale: "zh-cn",
  messages: getMessages(),
  warnHtmlInMessage: "off",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
});
