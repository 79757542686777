<template>
  <div id="app">
    <NavComponent />
    <div class="page-content">
      <router-view />
    </div>
    <FooterComponent />
  </div>
</template>
<script>
import NavComponent from "./components/Nav.vue";
import FooterComponent from "./components/Footer.vue";
import { jumpToDeviceUrl, saveCode } from "@/utils";
export default {
  name: "homePage",
  components: {
    NavComponent,
    FooterComponent,
  },
  created() {
    // 针对不同的设备，跳转到对应的链接   pc: www , h5: m
    jumpToDeviceUrl();

    // 保存渠道code
    saveCode();
  },
};
</script>

<style lang="less">
#app {
  color: #2c3e50;
}
.page-content {
  padding-top: 80px;
}
</style>
