<template>
  <div class="nav-bg">
    <div class="nav-wrap">
      <div class="logo-wrap fl" @click="routerJump()">
        <div class="logo"></div>
        <div class="slogan">
          <h1>{{ $t("nav-name") }}</h1>
          <p>{{ $t("nav-slogan") }}</p>
        </div>
      </div>
      <ul class="nav fl">
        <li @click="routerJump()">{{ $t("nav-home") }}</li>
        <li @click="routerJump('optimization')">{{ $t("nav-product") }}</li>
        <li @click="routerJump('price')">{{ $t("nav-price") }}</li>
        <li @click="routerJump('contact')">{{ $t("nav-contact") }}</li>
      </ul>
      <div class="language fr" @click="changeLanguage()">
        {{ language }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavPage",
  props: {
    msg: String,
  },
  data() {
    return {
      language: "English",
    };
  },
  methods: {
    changeLanguage() {
      if (this.$i18n.locale == "zh-cn") {
        this.$i18n.locale = "en";
        this.language = "中文";
      } else {
        this.$i18n.locale = "zh-cn";
        this.language = "English";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.nav-bg {
  width: 100%;
  height: 80px;
  background: #0057da;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  font-weight: 400;
  .nav-wrap {
    width: 1200px;
    margin: 0 auto;
    line-height: 40px;
    font-size: 18px;
    color: #fff;
    height: 80px;
    padding: 20px 0 0 0;
    .logo-wrap {
      overflow: hidden;
      margin-right: 60px;
    }
    .logo {
      width: 60px;
      color: #fff;
      cursor: pointer;
      height: 50px;
      float: left;
      margin-top: -2px;
      background: url(@/assets/logo.jpg) no-repeat left center;
      background-size: auto 100%;
    }
    .slogan {
      float: left;
      margin-left: 10px;
      line-height: 1.5;
      opacity: 0.9;
      h1 {
        font-size: 18px;
        font-weight: bold;
      }
      p {
        font-size: 12px;
        opacity: 0.8;
      }
    }
    .nav {
      li {
        float: left;
        margin-left: 50px;
        cursor: pointer;
        opacity: 0.9;
        &:hover {
          color: #ff905d;
        }
      }
    }
    .language {
      cursor: pointer;
      &:hover {
        color: #ff905d;
      }
    }
  }
}
</style>
